.spacer {
  padding: 120px 0;
}

/* ===========================================================================
  Navigation
*/

.navigation-center .navbar-brand {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.navigation-center .icon-menulist {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .navigation-center .navbar-brand {
    position: relative;
    top: auto;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  .navigation-center .icon-menulist {
    position: relative;
    top: auto;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@media (max-width: 991px) {
  .navigation-center .dropdown-menu,
  .navigation-center .dropdown-item.hover,
  .navigation-center .dropdown-item:hover,
  .navigation-center .dropdown-item.active,
  .navigation-center .dropdown-item:active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .navigation-center .dropdown-menu .dropdown-item {
    color: rgba(255, 255, 255, 0.65);
  }
}

/* ===========================================================================
  Main 01
*/

#owl-intro .img-symbol {
  max-width: 100% !important;
  width: auto !important;
}

.height-hero {
  height: 80vh;
}

@media (max-width: 574px) {
  .height-hero {
    height: 50vh;
  }
  #owl-intro .img-symbol {
    width: 100px !important;
  }
}

@-webkit-keyframes imagescale {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
  }
  100% {
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
  }
}

@keyframes imagescale {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
  }
  100% {
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
  }
}

.owl-item.active .imagescale {
  animation: imagescale 10s ease-in-out infinite alternate;
  -webkit-animation: imagescale 10s ease-in-out infinite alternate;
  -moz-animation: imagescale 10s ease-in-out infinite alternate;
  -o-webkit-animation: imagescale 10s ease-in-out infinite alternate;
  /* animation-fill-mode: forwards; */
}

/* ===========================================================================
  Main 02 :: Banner
*/

.wrap-feature1-box .card {
  margin-bottom: 0;
  border-radius: 0;
  margin-right: 1px;
  height: 100%;
}
.wrap-feature1-box .card.last {
  margin-right: 0px;
}
.wrap-feature1-box .card-body {
  padding: 60px 40px;
}
.bg-dark-light {
  background-color: #dedede;
}
@media only screen and (max-width: 1024px) {
  .feature1 .m-t {
    margin-top: 1px;
  }
}

/* ===========================================================================
  Main 03
*/
.height-main-half {
  /*  height: 50vh;*/
  padding-top: 120px;
  padding-bottom: 120px;
}

/* ===========================================================================
  Main 04
*/

.counter10 > * {
  color: #fff !important;
}

.counter10 {
  /* padding-top: 170px;
margin-top: -210px; */
}

/* ===========================================================================
  Main 05
*/

.testimonial3 .owl-carousel .owl-stage {
  margin-top: 50px;
}
.testimonial3 .owl-item {
  opacity: 0.25;
}

.testimonial3 .owl-item.center {
  opacity: 1;
}

.testimonial3 .card {
  background-color: transparent;
  border-radius: 0;
}

.testimonial3 .card .card-body {
  text-align: center;
}

.img-ho {
  /* overflow: hidden; */
  height: 100%;
}

.img-ho img {
  -webkit-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  -webkit-box-shadow: 0 70px 100px -40px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 70px 100px -40px rgba(0, 0, 0, 0.5);
  box-shadow: 0 70px 100px -40px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.owl-item.active.center .img-ho img:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-box-shadow: 0 40px 70px -40px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 40px 70px -40px rgba(0, 0, 0, 0.5);
  box-shadow: 0 40px 70px -40px rgba(0, 0, 0, 0.5);
}

/* ===========================================================================
  Main 06 :: Counter
*/

/* ===========================================================================
    Main 07
*/

/* ===========================================================================
Main 08
*/
.gutter-1px {
}
.gutter-1px .grid-gallery ul {
  margin-left: -3px;
}
.gutter-1px .grid li {
  padding: 0 0 3px 3px;
}

/* ===========================================================================
    Main 10 :: Contact
*/
.bg-light-2 {
  background-color: #f1f1f1;
}
@media (max-width: 767px) {
  .contact-2 .col-md-4 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .wrap-feature1-box .card {
    margin-bottom: 0;
    border-radius: 0;
    margin-right: 0px;
    height: 100%;
  }
}
