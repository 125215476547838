/*******************
Section 06  ****
*******************/
/*
/*
#bu {
	text-align: center;
}

.button {
	background-color: #1694ff;
	outline: 0;
	border: 0;
	width: 350px;
	height: 100px;
	border-radius: 20px;
	color: white;
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 24px;
	font-weight: 500;
	margin: 4px 2px;
	margin-bottom: 25px;
	cursor: pointer;
	-webkit-transition-duration: 0.4s;
	-o-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.button2:hover {
	background-color: #000095;
	box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
*/

.card-body {
  height: 50%;
}

.section6 {
  width: 100%;
  margin: 0 auto;
  top: 0px;
  overflow: hidden;
}
.section6_table {
  margin: 0;
  padding: 0;
}
.section6_table p {
  text-align: center;
  font-size: 2vmin;
  padding-bottom: 5vmin;
}
.ser_content .ser {
  width: 100%;
}
.ser_content .ser ul {
  width: 100%;
}
.ser_content .ser ul li {
  width: 50%;
  height: 60vh;
  float: left;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
}
.ser_content .ser ul li div {
  display: table;
  width: 100%;
}
.ser_content .ser ul li span {
  display: table-cell;
  vertical-align: middle;
}
.ser_content .ser ul .ser_con span {
  padding-left: 10vmin;
}
.ser_content .ser ul li div h4 {
  font-size: 2.5vmin;
  color: #000;
}
/*.ser_content .ser ul .ser_img {background-color: #404040;} */
.ser_content .ser ul .ser_img div {
  font-size: 3vmin;
}
.ser_content .ser ul .ser_img .img_1 {
  height: inherit;
  background: url(../images/img22.jpg) no-repeat center;
  background-size: cover;
  color: white;
  font-weight: 800;
}
.ser_content .ser ul .ser_img .img_2 {
  height: inherit;
  background: url(../images/img23.jpg) no-repeat center;
  background-size: cover;
  color: white;
  font-weight: 800;
}
.ser_content .ser ul .ser_img .img_3 {
  height: inherit;
  background: url(../images/img24.jpg) no-repeat center;
  background-size: cover;
  color: white;
  font-weight: 800;
}
.ser_content .ser ul .ser_img .img_4 {
  height: inherit;
  background: url(../images/img25.jpg) no-repeat center;
  background-size: cover;
  color: white;
  font-weight: 800;
}
.ser_content .ser ul .ser_con {
  padding-left: 7vmin; /* background-color: #f4f9ff;  */
  text-align: left;
  padding-top: 100px;
  padding-bottom: 100px;
}
.ser_content .ser ul .ser_con div {
  height: 33.3%;
  vertical-align: middle;
  font-size: 1.8vmin;
}
/* .ser_content .ser ul .ser_con .icon_1 {
  background: url(../images/icon_4.png) no-repeat left;
}
.ser_content .ser ul .ser_con .icon_2 {
  background: url(../images/icon_5.png) no-repeat left;
}
.ser_content .ser ul .ser_con .icon_3 {
  background: url(../images/icon_6.png) no-repeat left;
}
.ser_content .ser ul .ser_con .icon_4 {
  background: url(../images/icon_7.png) no-repeat left;
}
.ser_content .ser ul .ser_con .icon_5 {
  background: url(../images/icon_8.png) no-repeat left;
}
.ser_content .ser ul .ser_con .icon_6 {
  background: url(../images/icon_9.png) no-repeat left;
}
.ser_content .ser ul .ser_con .icon_7 {
  background: url(../images/icon_10.png) no-repeat left;
}
.ser_content .ser ul .ser_con .icon_8 {
  background: url(../images/icon_11.png) no-repeat left;
}
.ser_content .ser ul .ser_con .icon_9 {
  background: url(../images/icon_12.png) no-repeat left;
}
.ser_content .ser ul .ser_con .icon_10 {
  background: url(../images/icon_13.png) no-repeat left;
}
.ser_content .ser ul .ser_con .icon_11 {
  background: url(../images/icon_14.png) no-repeat left;
}
.ser_content .ser ul .ser_con .icon_12 {
  background: url(../images/icon_15.png) no-repeat left;
} */
.right {
  float: right;
}

.section6_text {
  width: 100%;
  margin-top: 20px;
  padding: 50px 0;
  text-align: center;
  background: rgba(35, 72, 167, 0.9647058824);
  color: #fff;
  font-size: 4vmin;
  font-weight: 500;
}
.section6_text .section6_btn {
  width: 200px;
  background: #fff;
  border: 1px #fff solid;
  color: #444;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 2vmin;
  margin-top: 15px;
  font-weight: bold;
}
.section6_text .section6_btn:hover {
  background-color: #204789;
  box-shadow:
    0 12px 16px 0 rgba(0, 0, 0, 0.2),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  color: #fff;
}

@media (min-width: 320px) and (max-width: 640px) {
  .section6 {
    position: relative;
    width: 100%;
    margin: 0 auto;
    top: 0px;
    overflow: hidden;
  }
  .section6_table {
    margin: 0;
    padding: 0;
  }
  .section6_table .ser_content .ser ul .ser_con div {
    font-size: 4.2vmin;
    background-size: 12%;
  }
  .ser_content .ser ul li {
    width: 100%;
    height: 40vh;
    float: left;
    text-align: center;
    vertical-align: middle;
    box-sizing: border-box;
  }
  .ser_content .ser ul .ser_con {
    padding-left: 4vmin;
    padding-right: 3vmin; /* background-color: #f4f9ff;  */
    text-align: left;
    padding-top: 0;
    padding-bottom: 0;
  }
  .ser_content .ser ul li div h4 {
    font-size: 4.8vmin;
    color: #000;
    font-weight: 600;
  }
  .ser_content .ser ul .ser_con span {
    padding-left: 15vmin;
    font-size: 4vmin;
    line-height: 4.5vmin;
  }
  .ser_content .ser ul .ser_img div {
    font-size: 6vmin;
  }

  .section6_text {
    width: 100%;
    margin-top: 20px;
    padding: 50px 0;
    text-align: center;
    background: rgba(35, 72, 167, 0.9647058824);
    color: #fff;
    font-size: 6vmin;
    font-weight: 400;
  }
  .section6_text .section6_btn {
    width: 200px;
    background: #fff;
    border: 1px #fff solid;
    color: #444;
    padding: 10px 0;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    font-size: 4.5vmin;
    margin-top: 15px;
  }
  .section6_text .section6_btn:hover {
    background-color: #204789;
    box-shadow:
      0 12px 16px 0 rgba(0, 0, 0, 0.2),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);
    color: #fff;
  }
}

@media (min-width: 320px) and (max-width: 640px) {
  .section6 {
    position: relative;
    width: 100%;
    margin: 0 auto;
    top: 0px;
    overflow: hidden;
  }
  .section6_table {
    margin: 0;
    padding: 0;
  }
  .section6_table .ser_content .ser ul .ser_con div {
    font-size: 4.2vmin;
    background-size: 12%;
  }
  .ser_content .ser ul li {
    width: 100%;
    height: 40vh;
    float: left;
    text-align: center;
    vertical-align: middle;
    box-sizing: border-box;
  }
  .ser_content .ser ul .ser_con {
    padding-left: 4vmin;
    padding-right: 3vmin; /* background-color: #f4f9ff;  */
    text-align: left;
    padding-top: 0;
    padding-bottom: 0;
  }
  .ser_content .ser ul li div h4 {
    font-size: 4.8vmin;
    color: #000;
    font-weight: 600;
  }
  .ser_content .ser ul .ser_con span {
    padding-left: 15vmin;
    font-size: 4vmin;
    line-height: 4.5vmin;
  }
  .ser_content .ser ul .ser_img div {
    font-size: 6vmin;
  }

  .section6_text {
    width: 100%;
    margin-top: 20px;
    padding: 50px 0;
    text-align: center;
    background: rgba(35, 72, 167, 0.9647058824);
    color: #fff;
    font-size: 6vmin;
    font-weight: 400;
  }
  .section6_text .section6_btn {
    width: 200px;
    background: #ffffff;
    border: 1px #fff solid;
    color: #444;
    padding: 10px 0;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    font-size: 4.5vmin;
    margin-top: 15px;
  }
  .section6_text .section6_btn:hover {
    background-color: #204789;
    box-shadow:
      0 12px 16px 0 rgba(0, 0, 0, 0.2),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);
    color: #fff;
  }
}

/*******************
Section 04
*******************/
.wrap-feature-18 {
  margin-top: 60px;
}
.wrap-feature-18 [class*='col-md-6'] .card {
  overflow: hidden;
}
.wrap-feature-18 .icon-position {
  position: relative;
  background-size: cover;
  background-position: center center;
  min-height: 200px;
}
/*
    .wrap-feature-18 .icon-position .icon-round {
      position: absolute;
      right: -38px;
      top: 20px; }

*/
.wrap-feature-24 {
  /* margin-top: 60px; */
}

.wrap-feature-24 .card {
  overflow: hidden;
  -o-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  -webkit-transition: 0.3s ease-out;
}

.wrap-feature-24 .card:hover {
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
}

.wrap-feature-24 .service-24 {
  text-align: center;
  padding: 40px 0;
  display: block;
}

.wrap-feature-24 .service-24 i {
  color: #4e8fc6;
  font-size: 50px;
}

.wrap-feature-24 .service-24 .ser-title {
  margin: 20px 0 0;
  font-weight: 500;
}

.wrap-feature-24 .service-24:hover,
.wrap-feature-24 .service-24:focus {
  background: #4e8fc6;
  background: -moz-linear-gradient(top, #4e8fc6 0%, #74b5ec 100%);
  background: -webkit-linear-gradient(top, #4e8fc6 0%, #74b5ec 100%);
  background: linear-gradient(to bottom, #4e8fc6 0%, #74b5ec 100%);
}

.wrap-feature-24 .service-24:hover i,
.wrap-feature-24 .service-24:hover .ser-title,
.wrap-feature-24 .service-24:focus i,
.wrap-feature-24 .service-24:focus .ser-title {
  color: #ffffff;
  text-fill-color: #ffffff;
  -webkit-text-fill-color: #ffffff;
}

.wrap-feature-24 .service-24:hover i,
.wrap-feature-24 .service-24:focus i {
  margin-bottom: 5px;
}

.feature42 {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.feature42 .wrap-feature42-box {
  padding: 8% 0;
}
.modal-style-1 .modal-content {
  background-color: transparent;
  border: none;
}
.modal-style-1 .modal-header {
  border: none;
}
.modal-style-1 .modal-body {
  padding: 0 !important;
}

@media (min-width: 1200px) {
  .modal-lg {
    max-width: 1000px;
  }
}

.feature51 {
  overflow: hidden;
}

.feature51 .box2 {
  display: inline-block;
  margin-top: 40px;
}

.feature51 .box2 dl {
  width: 360px;
  height: 360px;
  display: block;
  border-radius: 360px;
  background: rgba(197, 197, 197, 0.14);
  padding: 90px 64px 0;
  text-align: center;
  float: left;
  margin-right: -13px;
  margin-left: -13px;
}

.feature51 .box2 dl dt {
  font-size: 28px;
  font-weight: 400;
  color: #000;
}

.feature51 .box2 dl dt .support_text {
  display: block;
}

/* .feature51 .box2 dl.left {}

  .feature51 .box2 dl.right {}

  .feature51 .box2 dl dt {}

  .feature51 .box2 dl dd {} */

@media (max-width: 1199px) {
  .feature51 .box2 dl {
    width: 320px;
    height: 320px;
    padding: 75px 60px 0;
  }
  .feature51 .box2 dl dt {
    font-size: 25px;
  }
}

@media (max-width: 991px) {
  .feature51 .box2 {
    margin-bottom: -50px;
  }
  .feature51 .box2 dl {
    width: 300px;
    height: 300px;
    padding: 60px 10px 0;
    margin-right: -12px;
    margin-left: -12px;
  }
  .feature51 .box2 dl.left {
    animation: none;
    -webkit-animation: none;
  }
  .feature51 .box2 dl.right {
    float: none;
    clear: both;
    margin: 0 auto;
    transform: translate(0, -25%);
    animation: none;
    -webkit-animation: none;
  }
  .feature51 .box2 dl dt {
    /* font-size: 1.5rem; */
  }
}

@media (max-width: 767px) {
  .feature51 .box2 dl {
    width: 260px;
    height: 260px;
    padding: 50px 10px 0;
  }
}

@media (max-width: 576px) {
  .feature51 .box2 {
    width: 100%;
    height: auto;
    margin: 30px 0;
  }
  .feature51 .box2 dl {
    float: none;
    transform: translate(0, 0) !important;
    margin: 0 0 8px 0;
    width: 100%;
    padding: 30px 27px;
    height: auto;
    overflow: hidden;
  }
  .feature51 .box2 dl dt {
    float: left;
    text-align: left;
    width: 45%;
    padding-right: 10px;
    font-size: 18px;
    font-weight: 500;
  }
  .feature51 .box2 dl dd {
    width: 55%;
    float: left;
    text-align: left;
    padding-left: 10px;
    margin-top: 0 !important;
  }
  .feature51 .box2 dl dd br {
    display: none;
  }
}

.data_table_list_outer {
  padding: 100px 0;
}

.data_table_list {
  display: table;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-top: 2px solid #222;
}

.data_table_list > li {
  display: table-row;
}

.data_table_list > li > b,
.data_table_list > li > span {
  display: table-cell;
  padding: 13px 18px 13px 0;
  border-bottom: 1px solid #ddd;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.data_table_list > li > b {
  width: 180px;
  padding-left: 40px;
  font-size: 16px;
  font-weight: 600;
  color: #222;
}

.data_table_list > li > span {
  padding-left: 23px;
  font-size: 16px;
  color: #666;
}

@media (max-width: 1023px) {
  .data_table_list_outer {
    padding: 7%;
  }
  .data_table_list {
    max-width: inherit;
  }
  .data_table_list > li > b {
    width: 155px;
    padding-left: 30px;
    font-size: 15px;
  }
  .data_table_list > li > span {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .data_table_list_outer {
    padding: 8% 5%;
  }
  .data_table_list {
    display: block;
  }
  .data_table_list > li {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  .data_table_list > li > b,
  .data_table_list > li > span {
    display: block;
    padding: 0;
    border-bottom: none;
  }
  .data_table_list > li > b {
    width: auto;
    padding-left: 0;
    font-size: 14px;
    line-height: 1.4;
  }
  .data_table_list > li > span {
    padding-left: 0;
    padding-top: 3px;
    font-size: 14px;
    line-height: 1.4;
  }
  .data_table_list > li > span br {
    display: none;
  }
  .table_list_blankspace,
  .table_list_small_blankspace {
    display: none;
  }
}

@media (max-width: 480px) {
  .data_table_list_outer.wrap {
    margin: 0;
  }
  .data_table_list_outer {
    padding: 30px 15px;
  }
}

.shadow-lg {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.shadow-md {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.service-3 ul.list-service {
  position: relative;
  z-index: 1;
  display: block;
}
.service-3 ul.list-service > li:nth-child(1) {
  float: left;
  width: 49%;
}

.service-3 ul.list-service > li:nth-child(2) {
  float: right;
  width: 40.5%;
  margin-top: 192px;
}

.service-3 ul.list-service > li:nth-child(3) {
  float: left;
  width: 49%;
  margin-top: 70px;
}

.service-3 ul.list-service .card-img-top {
  height: 100%;
}

@media (max-width: 1280px) {
  .service-3 ul.list-service > li:nth-child(1) {
    width: 50%;
    max-width: 588px;
  }
  .service-3 ul.list-service > li:nth-child(2) {
    width: 45%;
    max-width: 486px;
  }
  .service-3 ul.list-service > li:nth-child(3) {
    width: 50%;
    max-width: 588px;
  }
}

@media (max-width: 1023px) {
  .service-3 ul.list-service > li:nth-child(2) {
    width: 46%;
    margin-top: 20%;
  }
  .service-3 ul.list-service > li:nth-child(3) {
    margin-top: 6%;
  }
}

@media (max-width: 767px) {
  .service-3 ul.list-service > li:nth-child(1),
  .service-3 ul.list-service > li:nth-child(2),
  .service-3 ul.list-service > li:nth-child(3) {
    width: 48%;
  }
}

@media (max-width: 576px) {
  .service-3 ul.list-service > li:nth-child(1) {
    width: 100%;
  }
  .service-3 ul.list-service > li:nth-child(2) {
    width: 100%;
    margin-top: 0;
  }
  .service-3 ul.list-service > li:nth-child(3) {
    width: 100%;
    margin-top: 0;
  }
}

.service-3 .card-body {
  padding: 0;
}
.service-3 ul.list-underline > li {
  border-top: 1px solid #ddd;
}

.service-2 ul {
  border-top: 2px solid #222;
}
.service-2 li {
  padding-top: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid #ddd;
}
.service-2 li h5,
.service-2 li p {
  padding-left: 50px;
}

@media (max-width: 767px) {
  .service-2 li {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .service-2 .list-text {
    margin-top: 20px;
  }
  .service-2 li h5,
  .service-2 li p {
    padding-left: 15px;
  }
}

.wrap-feature45-box {
  margin-top: 60px;
}

.wrap-feature45-box .f45-tab {
  display: table;
  width: 100%;
}

.wrap-feature45-box .f45-tab > a {
  display: table-cell;
  border: 1px solid rgba(120, 130, 140, 0.13);
  border-bottom-color: #222;
  text-align: center;
  padding: 15px 10px;
  background: #f4f8fa;
  color: rgba(38, 50, 56, 0.4);
}

.wrap-feature45-box .f45-tab > a.active {
  border: 1px solid #222;
  border-bottom-color: transparent;
  background: #ffffff;
  font-weight: 400;
  color: rgba(38, 50, 56, 1);
}

.wrap-feature45-box .tab-pane {
  padding: 60px 0;
}

.wrap-feature45-box .tab-pane ul {
  padding-top: 40px;
}
.wrap-feature45-box .tab-pane li:not(:last-child) {
  position: relative;
  padding-right: 100px;
}
.wrap-feature45-box .tab-pane li:not(:last-child):after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 50px;
  width: 1px;
  height: 80px;
  background-color: #eee;
  content: '';
}

@media (max-width: 767px) {
  .wrap-feature45-box .tab-pane {
    padding: 30px 0;
  }
  .wrap-feature45-box .tab-pane ul {
    padding-top: 0px;
  }

  .wrap-feature45-box a.nav-item i {
    font-size: 24px;
  }
}

/* Service */
.height-v {
  height: 500px;
}

.height-p {
  height: 300px;
}

@media (max-width: 991px) {
  .height-v {
    height: 320px;
  }
}

.list-icon-2 {
  padding-top: 20px;
}

.list-icon-2 li {
  position: relative;
  padding-bottom: 10px;
  line-height: 1.45;
  padding-left: 30px;
}

.list-icon-2 i {
  position: absolute;
  top: 5px;
  left: 0;
  margin-right: 10px;
}

/*******************
    Pricing 2
    *******************/
.pricing2 {
  background-size: cover;
  max-height: 630px;
  margin-bottom: 400px;
}
.pricing2 .pricing-box {
  margin-top: 50px;
}

@media (max-width: 1200px) {
  .pricing2 {
    background-size: cover;
    max-height: 530px;
    margin-bottom: 300px;
  }
  .pricing2 .pricing-box {
    margin-top: 80px;
  }
}
@media (max-width: 1023px) {
  .pricing2 {
    max-height: 100%;
    margin-bottom: 0px;
  }
  .pricing2 .pricing-box {
    margin-top: 20px;
  }
}

.spacer {
  padding: 80px 0;
}
